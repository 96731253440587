
import { defineComponent } from "vue";
import CalendarMonth from "@/components/CalendarMonth.vue"; // @ is an alias to /src
import SingleDay from "@/components/SingleDay.vue"; // @ is an alias to /src

const monthNames = [
  "Tháng 1",
  "Tháng 2",
  "Tháng 3",
  "Tháng 4",
  "Tháng 5",
  "Tháng 6",
  "Tháng 7",
  "Tháng 8",
  "Tháng 9",
  "Tháng 10",
  "Tháng 11",
  "Tháng 12",
];

export default defineComponent({
  name: "Month",
  components: {
    CalendarMonth,
    SingleDay,
  },
  mounted() {},
  computed: {
    year() {
      const yearInput = this.$route.params.year as string;
      if (!yearInput) {
        return undefined;
      }
      return Number(yearInput);
    },
    month() {
      const monthInput = this.$route.params.month as string;
      if (monthInput == undefined || isNaN(Number(monthInput))) {
        return undefined;
      }
      return Number(monthInput);
    },
    lastMonthPath(): string | undefined {
      if (this.month === undefined || this.year === undefined) {
        return undefined;
      }
      let lastMonth = this.month;
      let year = this.year;
      if (lastMonth < 1) {
        year = year - 1;
        lastMonth = 11;
      } else {
        lastMonth = lastMonth - 1;
      }
      return `/year/${year}/month/${lastMonth}`;
    },
    nextMonthPath(): string | undefined {
      if (this.month === undefined || this.year === undefined) {
        return undefined;
      }
      let nextMonth = this.month;
      let year = this.year;
      if (nextMonth > 10) {
        year = year + 1;
        nextMonth = 0;
      } else {
        nextMonth = nextMonth + 1;
      }
      return `/year/${year}/month/${nextMonth}`;
    },
    yearLbl(): string | undefined {
      const yearInput = this.$route.params.year as string;
      if (!yearInput) {
        return undefined;
      }
      return "Năm " + yearInput;
    },
    months(): Number[] {
      const month = [];
      for (let index = 0; index < 12; index++) {
        month.push(index);
      }
      return month;
    },
    monthNames(): string[] {
      return monthNames;
    },
    monthLbl(): string {
      return monthNames[Number(this.month)];
    },
  },
});
