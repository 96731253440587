
import getWeekOfMonth from "date-fns/getWeekOfMonth";
import lastDayOfMonth from "date-fns/lastDayOfMonth";
import CalendarDay from "@/components/CalendarDay.vue"; // @ is an alias to /src
import CalendarWeek from "@/components/CalendarWeek.vue"; // @ is an alias to /src
import { defineComponent } from "vue";

export default defineComponent({
  name: "CalendarMonth",
  components: {
    CalendarDay,
    CalendarWeek,
  },
  data() {
    return {
      days: {} as Record<number, Date>,
    };
  },
  props: {
    year: Number,
    month: Number,
  },
  computed: {
    theFirstDayOfMonth(): Date | undefined {
      if (!this.year || isNaN(Number(this.month))) {
        return undefined;
      }
      return new Date(this.year, Number(this.month), 1);
    },
    theLastDayOfMonth(): Date | undefined {
      if (!this.theFirstDayOfMonth) {
        return undefined;
      }
      return lastDayOfMonth(this.theFirstDayOfMonth);
    },
    totalWeeks(): number | undefined {
      if (!this.theLastDayOfMonth) {
        return undefined;
      }
      return getWeekOfMonth(this.theLastDayOfMonth);
    },
    weeks(): number[] | undefined {
      if (!this.totalWeeks) {
        return undefined;
      }
      return new Array(this.totalWeeks).fill("").map((e, i) => i + 1);
    },
    weekDays(): string[] {
      const dayNames = ["CN", "T2", "T3", "T4", "T5", "T6", "T7"];
      return new Array(7).fill("").map((e, i) => dayNames[i]);
    },
  },
  mounted() {
  },
});
