<template>
  <div class="week">
    <div class="day-item" v-for="day in dateMap" :key="'day' + day.toISOString()">
      <slot
        name="day"
        :year="day.getFullYear()"
        :month="day.getMonth()"
        :day="day.getDate()"
        :scopeYear="year"
        :scopeMonth="month"
        :scopeWeek="week"

      >
        <CalendarDay
          :year="day.getFullYear()"
          :month="day.getMonth()"
          :day="day.getDate()"
        >
        </CalendarDay>
      </slot>
    </div>
  </div>
</template>

<script lang="ts">
import { startOfWeek } from "date-fns";
import { defineComponent } from "vue";
import CalendarDay from "@/components/CalendarDay.vue"; // @ is an alias to /src
export default defineComponent({
  name: "CalendarWeek",
  components: {
    CalendarDay,
  },
  props: {
    year: Number,
    month: Number,
    week: Number,
  },
  computed: {
    firstDateOfWeek(): Date | undefined {
      if (!this.year || isNaN(Number(this.month)) || isNaN(Number(this.week))) {
        return undefined;
      }
      let date = new Date(
        this.year,
        Number(this.month),
        Number(this.week) * 7 -
          new Date(this.year, Number(this.month), 1).getDay()
      );
      return startOfWeek(date);
    },
    dateMap(): Record<number, Date> | undefined {
      if (!this.firstDateOfWeek) {
        return undefined;
      }
      const days = {} as Record<number, Date>;
      for (let index = 0; index < 7; index++) {
        days[index] = new Date(
          this.firstDateOfWeek.getFullYear(),
          this.firstDateOfWeek.getMonth(),
          this.firstDateOfWeek.getDate() + index
        );
      }

      return days;
    },
  },
});
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.week {
  display: flex;
  flex: 1;
}
.week > .day-item {
  flex: 1;
}
</style>
