<template>
  <div
    class="day"
    :class="{
      'to-day': isToday,
      'current-month-scope': isSameMonth,
    }"
  >
    <span draggable="false" class="solar-date noselect">{{ day }}</span>

    <span draggable="false" class="lunar-date noselect">
      {{ lunarDateLbl }}
    </span>
  </div>
</template>

<script lang="ts">
import { getLunarDate, LunarDate } from "@/helpers/am-lich-new";
import { isSameDay } from "date-fns";
import { defineComponent } from "vue";

export default defineComponent({
  name: "SingleDay",
  props: {
    year: Number,
    month: Number,
    day: Number,
    scopeYear: Number,
    scopeMonth: Number,
    scopeWeek: Number,
  },
  computed: {
    date(): Date | undefined {
      if (!this.year || !this.day || isNaN(Number(this.month))) {
        return undefined;
      }
      return new Date(this.year, Number(this.month), this.day);
    },
    isDate(): boolean {
      return this.date instanceof Date;
    },
    lunarDate(): number[] | undefined {
      if (!this.date || !this.isDate) {
        return undefined;
      }
      const lunarDate: LunarDate = getLunarDate(
        Number(this.day),
        Number(this.month) + 1,
        Number(this.year)
      );

      return [lunarDate.day, lunarDate.month];
    },
    lunarDateLbl(): string | undefined {
      if (this.lunarDate === undefined) {
        return undefined;
      }
      return this.lunarDate[0] == 1 || this.isTheFirstDayOfScope
        ? this.lunarDate.join("/")
        : this.lunarDate[0].toString();
    },
    isSameMonth(): boolean {
      return this.year === this.scopeYear && this.month === this.scopeMonth;
    },
    isTheFirstDayOfScope(): boolean {
      return this.scopeWeek === 1 && this.date?.getDay() === 0;
    },
    isToday(): boolean {
      return !!this.date && isSameDay(this.date, new Date());
    },
  },
});
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
.day {
  display: flex;
  flex: 1;
  padding: 5px;
  flex-direction: column;
  opacity: 0.5;
}
.lunar-date {
  color: #111;
  font-size: xx-small;
}
.solar-date {
  color: black;
}
@media screen and (max-width: 320px) {
  .day {
    font-size: 12px;
    padding: 3px 5px;
  }
}
@media screen and (max-width: 240px) {
  .day {
    font-size: 9px;
    padding: 3px 5px;
  }
}

.day.to-day {
  border: 1px solid #ffaebd;
  background: #ffe0e6;
}
.day.current-month-scope {
  opacity: 1;
}
</style>
