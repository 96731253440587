<template>
  <div class="month">
    <div class="week-header">
      <div
        draggable="false"
        class="day-name noselect"
        v-for="dayName of weekDays"
        :key="dayName"
      >
        <strong>
          {{ dayName }}
        </strong>
      </div>
    </div>
    <CalendarWeek
      v-for="week of weeks"
      :key="'week' + week"
      :year="year"
      :month="month"
      :week="week"
    >
      <template v-slot:day="dayProps">
        <slot
          name="day"
          :year="dayProps.year"
          :month="dayProps.month"
          :day="dayProps.day"
          :scopeYear="dayProps.scopeYear"
          :scopeMonth="dayProps.scopeMonth"
          :scopeWeek="dayProps.scopeWeek"
        >
        </slot>
      </template>
    </CalendarWeek>
  </div>
</template>

<script lang="ts">
import getWeekOfMonth from "date-fns/getWeekOfMonth";
import lastDayOfMonth from "date-fns/lastDayOfMonth";
import CalendarDay from "@/components/CalendarDay.vue"; // @ is an alias to /src
import CalendarWeek from "@/components/CalendarWeek.vue"; // @ is an alias to /src
import { defineComponent } from "vue";

export default defineComponent({
  name: "CalendarMonth",
  components: {
    CalendarDay,
    CalendarWeek,
  },
  data() {
    return {
      days: {} as Record<number, Date>,
    };
  },
  props: {
    year: Number,
    month: Number,
  },
  computed: {
    theFirstDayOfMonth(): Date | undefined {
      if (!this.year || isNaN(Number(this.month))) {
        return undefined;
      }
      return new Date(this.year, Number(this.month), 1);
    },
    theLastDayOfMonth(): Date | undefined {
      if (!this.theFirstDayOfMonth) {
        return undefined;
      }
      return lastDayOfMonth(this.theFirstDayOfMonth);
    },
    totalWeeks(): number | undefined {
      if (!this.theLastDayOfMonth) {
        return undefined;
      }
      return getWeekOfMonth(this.theLastDayOfMonth);
    },
    weeks(): number[] | undefined {
      if (!this.totalWeeks) {
        return undefined;
      }
      return new Array(this.totalWeeks).fill("").map((e, i) => i + 1);
    },
    weekDays(): string[] {
      const dayNames = ["CN", "T2", "T3", "T4", "T5", "T6", "T7"];
      return new Array(7).fill("").map((e, i) => dayNames[i]);
    },
  },
  mounted() {
  },
});
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.week-header {
  display: flex;
  min-height: 2rem;
}
.day-name {
  flex: 1;
  align-items: center;
  padding: 5px;
}
.week > .day-item {
  flex: 1;
}
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
.inactive {
  opacity: 50%;
}
</style>
