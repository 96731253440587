
import { getLunarDate, LunarDate } from "@/helpers/am-lich-new";
import { isSameDay } from "date-fns";
import { defineComponent } from "vue";

export default defineComponent({
  name: "SingleDay",
  props: {
    year: Number,
    month: Number,
    day: Number,
    scopeYear: Number,
    scopeMonth: Number,
    scopeWeek: Number,
  },
  computed: {
    date(): Date | undefined {
      if (!this.year || !this.day || isNaN(Number(this.month))) {
        return undefined;
      }
      return new Date(this.year, Number(this.month), this.day);
    },
    isDate(): boolean {
      return this.date instanceof Date;
    },
    lunarDate(): number[] | undefined {
      if (!this.date || !this.isDate) {
        return undefined;
      }
      const lunarDate: LunarDate = getLunarDate(
        Number(this.day),
        Number(this.month) + 1,
        Number(this.year)
      );

      return [lunarDate.day, lunarDate.month];
    },
    lunarDateLbl(): string | undefined {
      if (this.lunarDate === undefined) {
        return undefined;
      }
      return this.lunarDate[0] == 1 || this.isTheFirstDayOfScope
        ? this.lunarDate.join("/")
        : this.lunarDate[0].toString();
    },
    isSameMonth(): boolean {
      return this.year === this.scopeYear && this.month === this.scopeMonth;
    },
    isTheFirstDayOfScope(): boolean {
      return this.scopeWeek === 1 && this.date?.getDay() === 0;
    },
    isToday(): boolean {
      return !!this.date && isSameDay(this.date, new Date());
    },
  },
});
