
import { defineComponent } from "vue";

export default defineComponent({
  name: "CalendarDay",
  props: {
    year: Number,
    month: Number,
    day: Number,
  },
  computed: {
    date(): Date | undefined {
      if (!this.year || !this.day || isNaN(Number(this.month))) {
        return undefined;
      }
      return new Date(this.year, Number(this.month), this.day);
    },
    isDate(): boolean {
      return this.date instanceof Date;
    },
  },
});
