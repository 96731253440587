<template>
  <div class="day">
    <span draggable="false" class="solar-date noselect">{{ day }}</span>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "CalendarDay",
  props: {
    year: Number,
    month: Number,
    day: Number,
  },
  computed: {
    date(): Date | undefined {
      if (!this.year || !this.day || isNaN(Number(this.month))) {
        return undefined;
      }
      return new Date(this.year, Number(this.month), this.day);
    },
    isDate(): boolean {
      return this.date instanceof Date;
    },
  },
});
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
.day {
  display: flex;
  flex: 1;
  padding: 5px;
  flex-direction: column;
}
.lunar-date {
  color: #111;
  font-size: xx-small;
}
.solar-date {
  color: black;
}
@media screen and (max-width: 320px) {
  .day {
    font-size: 12px;
    padding: 3px 5px;
  }
}
@media screen and (max-width: 240px) {
  .day {
    font-size: 9px;
    padding: 3px 5px;
  }
}
</style>
